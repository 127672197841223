.container-login100 {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items:center;
  padding: 15px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1; 
  background: #bababa;
  background: -webkit-linear-gradient(bottom, #bababa, #c2d2ff);
  background: -moz-linear-gradient(bottom, #bababa, #c2d2ff);
  background: linear-gradient(to top, #bababa, #c2d2ff); 
}
.imglogo{
  width: 250px;
  margin: 0px auto;
}
.loginDiv{
  display: inline-block;
  width: 100%;
  margin-top: 70px;
}
.gptlogoImg{
  width: 64px;
  display: block;
  margin: auto;
}
.loginDiv button img{
  width: 32px;
}
.loginDiv button{
  background: #fff;
  color: #333;
  margin-top: 10px;

}
.loginInnerBox {
  padding: 30px;
  border-radius: 20px;
  width: 500px;
  background: #306a88;
  box-shadow: 0 10px 10px #306a8869;
}
/*
.plans {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: #fff;
  border-radius: 20px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.plans .plan input[type="radio"] {
  position: absolute;
  opacity: 1;
  border-color: #00486c;
  left: 0;
  margin: 10px;
}
.plans .plan {
  cursor: pointer;
  width: 18%;
  position: relative;
  background: #F5F6FA;
  border-radius: 10px;
  margin: 10px auto !important;
  margin-right: 20px !important;
}  */
.titletxtRole{
  color: #333;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.5px;
}
.optionList{
  text-align: left;
}
.btnStylingsubmit {
  width: 140px;
  display: inline-block;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 34px;
  text-align: center;
  letter-spacing: 0.5px;
  margin: 50px auto;
  color: #ffffff!important;
  background: #00486c !important;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.15);
  border-radius: 50px; 
}
.box {
  position:relative;
 /* top:50%;
  left:50%;
  transform:translate(-50%,-50%);*/
  width:80%;
  margin: auto;
  padding:20px;
  background:#fff;
  color:#fff;
  text-align:center;
  box-shadow:0 5px 20px rgba(0,0,0,1);
  border-radius:5px;
  overflow:hidden;
}  
.box p {
  margin:0;
  padding:0;
  color:#fff;
  font-size:50px;
}
.box label {
  position:relative;
  cursor:pointer;
}
.box label input {
  display:none;
}
.box label span {
  position:relative;
  display:inline-block;
  margin: 15px 10px;
  font-size:24px; 
  padding: 8px 20px;
  width: auto;
  background: #fff;
  border: 1px solid #444;
  color: #444;
  border-radius: 4px;
}
.box label input:checked ~ span {
  color:#fff;
  border: 1px solid #008eff;
}
.box label input:checked ~ span:before {
  content:'';
  width: 100%;
  height: 100%;
  position:absolute;
  top:0;
  left:0;
  background:#fff;
  z-index:-1;
  filter: blur(10px);
}
.box label input:checked ~ span:after {
  content:'';
  width: 100%;
  height: 100%;
  position:absolute;
  top:0;
  left:0;
  background:#fff;
  z-index:-1;
  filter: blur(15px);
}
.box label input:checked ~ span.yes {
  color:#363698;
  border-color:#363698; 
  font-weight: 600;
  background: #e4e3e9;
  border: 3px solid;
}
.box label input:checked ~ span.no {
  color:#ff0000;
  border-color:#ff0000;
  box-shadow: inset 0 0 10px #ff0000;
}

.box label input:checked ~ span.no:before,
.box label input:checked ~ span.no:after {
  background:#ff0000;
} 
.chatGPTDivBox .header{
  height: 60px;  
  background: #306A88;
  width: 100%;
  display: inline-block;
  padding: 2px 10px;
  border-bottom: 2px solid #306A88;
} 
.chatGPTDivBox .header .icon{
  height: 55px;
  display: inline-block;
  float: left;
  text-align: left;
}
.chatGPTDivBox .userInfoTxt h3{
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 100% !important;
  width: 100px;
  display: block;
  color: #fff;
  overflow-wrap: break-word;
  float: left;
  margin: 0 5px 0 0;
}
.chatGPTDivBox .userInfoTxt h3 b{
  display: block;
  font-size: 12px;
}
.chatGPTDivBox .userInfoTxt a{
  float: right;
  color: #fff;
  display: inline-block;
 /* background: #902f11;*/
  font-size: 10px;
  width: 32px;
  height: 32px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  filter: brightness(3);
}
.chatGPTDivBox .userInfoTxt a:hover{
  filter: brightness(1);
}
.chatGPTDivBox .userInfoTxt a img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.chatGPTDivBox .userInfoTxt {
  display: flex;
  float: right;
  width: 150px;
  justify-content: center;
  align-items: center;
  height: 100%;
} 

.footerDiv{
  position: absolute;  
  border-top: 2px solid #306A88;
  bottom: 10px;
  width: 99%;
  height: 50px;
  padding: 10px;
  margin: 0 2px;
  left: 0;
  background: #fff;
} 
.footerDiv input{
  float: left;
  width: 80%;
}
.footerDiv .inputClass{
  float: left;
  width: 60%;
}
.footerDiv button{
      padding: 8px 0;
      color: #fff;
      background-color: #306A88;
      border: none;
      border-radius: 4px;
      text-align: center;
      cursor: pointer;
      display: inline-block;
      width: 80px;
      margin-left: 10px;
      margin-top: 0px;
      cursor: pointer;
} 
.autoChatList::-webkit-scrollbar-track
{ 
    background-color: #EEEEEE;    
  border-radius: 10px;
} 
.autoChatList::-webkit-scrollbar
{
    width: 10px;
    background-color: #EEEEEE;  
  border-radius: 10px;
} 
.autoChatList::-webkit-scrollbar-thumb
{
    background-color: #D3D3D3; 
  border-radius: 10px;
}
.chatListBox{
  display: inline-block;
  width: 95%;
  margin: auto;
}
ul.autoChatList{
  list-style: none;
  padding: 0;
  width: 100%;
  height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
  display: inline-block;
}
.autoChatList li{
  width: 100%;
  display: inline-block;
  padding-bottom: 2px;
}
 .autoChatList li b{
  display: block;
  height: 100%;
  width: 20px;
  float: left;
 }
 .autoChatList li span{
  display: inline-block;
    width: 96%;
    line-height: 28px;
 }
  .autoChatList li label{
  width: 98%;
  display: block;
  padding: 0px 10px;
}
.autoChatList li label.question,
.autoChatList li label.answer{
  display: block;
  margin: 0 5px;
  width: 99%;
  text-align: left;
}
.autoChatList li label.answer{
  text-align: left;
  float: left;
  background: #e0e0e0;
  border-radius: 0px;
  padding: 20px 0 10px;
  color: #306A88;
}
.autoChatList li label.question{
  text-align: left;
  float: left;
}
.autoChatList li label.answer b{
  background: #306A88;
  color: #fff;
  border-radius: 50px;
  width: 32px;
  height: 32px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: baseline;
  margin-right: 10px;
  overflow: hidden; 
}
.chatImg{
  padding: 3px;
  width: 100%;
  object-fit: cover;
  height: 100%;
  object-position: center;
  display: inline-block;
}
.autoChatList li label.question b{
  float: left;
  background: #fff;
  color: #306A88;
  border-radius: 50px;
  width: 32px;
  height: 32px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}
.autoChatList li label.question{
  background: #306A88;
  border-radius: 0px;   
  padding: 20px 0;
  color: #fff;
}
.welcomeTxt{
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  margin:10px 0 20px;
  color: #306A88;
}

.footerDiv button.submitBtn {
  width: 120px;
}
.autoChatList li pre {
display: inline-block;
word-break: break-word;
margin-bottom: 0;
overflow: auto; 
white-space: pre-wrap;
font-family: 'Poppins', sans-serif;
font-style: normal;
font-weight: 500;
font-size: 16px;
width: 90%; 
position: relative;
top: 0px;
}
.loginDiv iframe{
  text-align: center;
  margin: auto !important;
}
 /*------------------Media Query------------*/

 @media screen and (max-width: 1200px) {
  .footerDiv .container{
    max-width: 98%;
}
.footerDiv input {
  float: left;
  width: 78%;
  }
.footerDiv .inputClass {
  float: left;
  width: 58%;
  }
 }
 
 @media screen and (max-width: 1100px) { 
.footerDiv input {
  float: left;
  width: 75%;
  }
.footerDiv .inputClass {
  float: left;
  width: 55%;
  }
 }
 @media screen and (max-width: 991px) {
      .container{
          max-width: 98%;
      }
      .footerDiv input {
          float: left;
          width: 70%;
      }
      .footerDiv .inputClass {
          float: left;
          width: 50%;
      }
      .footerDiv .container{
          max-width: 100%;
      }
 }
  
 @media screen and (max-width: 800px) { 
  .footerDiv input {
    float: left;
    width: 65%;
    }
  .footerDiv .inputClass {
    float: left;
    width: 45%;
    }
   }
 @media screen and (min-width: 680px) {
      .autoChatList li span {
        display: inline-block;
        width: 90%;
    }
}

@media screen and (max-width: 680px) {    
 
      .autoChatList li span {
        width: 90%;
      }

      .footerDiv input {
          float: left;
          width: 100%;
          margin-bottom: 10px;
      }
      .footerDiv .inputClass {
          float: left;
          width: 100%;
          margin-bottom: 10px;
      }
      .footerDiv {  
        height: 100px;
      }
      .loginDiv button { 
        font-size: 13px;
        padding: 5px 10px;
      }
      .loginDiv button img {
        width: 24px;
      }
} 

@media screen and (max-width: 500px) {  
  .autoChatList li pre,
  .autoChatList li span {
    width: 85%;
  }
  ul.autoChatList { 
    height: 60vh;
    width: 98%;
  }
  .footerDiv button {
    padding: 5px 0;
  }
  .footerDiv input { 
    margin-bottom: 7px;
  }
  .footerDiv .inputClass { 
    margin-bottom: 7px;
  }
  .footerDiv { 
    bottom: 0px;
    padding: 5px 10px;
    height: 90px;
  }
}
.view-candidates.css-h2bfsy {
  width: 1460px!important;
}

.view-candidates-parent .css-25ibci-MuiPaper-root-MuiDialog-paper, .view-candidates-parent .MuiPaper-root {
  max-width: 1500px !important;
  width: 1400px !important;
}

.css-120gsus img {
  max-width: 250px;
  height: auto;
}


.LeftSection,  .RightSection{
  overflow-x: hidden;
  margin: 0 !IMPORTANT;
}
.test_modal .MuiDialog-paper {
  width: 95%;
}
.RightSection{
max-height: 480px;
}
/* .RightSection::-webkit-scrollbar{
  display: none;
} */
.MuiCircularProgress-root {
  width: 25px  !important;
  height: 25px !important;
  position: absolute !important;
  right: 20px;
  top: 7px;
  color: #fff !important;
}
.RightSection > div {
  padding-left: 0;
}
.btn_group button {
  flex-grow: 1;
}
.easy-edit-inline-wrapper input {
  margin-bottom: 10px;
}
.manual_question li {
  align-items: flex-start;
  padding-left: 0;
}
label.coding_question {
  margin-right: 0;
  align-items: flex-start;
  margin-bottom: 15px;
  margin-left: 0;
}
label.coding_question span {
  padding-top: 0;
  margin-right: 5px;
}
.question_list {
  align-items: center;  
}

.question_list li:nth-child(3) {
  justify-content: flex-end;
  padding-right: 0;
  flex: 0 0 20%;
  width: 20%;
}

.questions {
  width: 95%;
  flex: 0 0 95%;
  list-style-type: none;
  align-items: flex-start !important;
}

.MuiDialogTitle-root {
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 11;
}
.xl_modal .MuiDialog-paper {
  width: 100%;
  max-width: 900px;
}

.xxl_modal .MuiDialog-paper {
  width: 100%;
  max-width: 1200px;
}

.qa_list ul {
  border: 1px solid #eaeaea;
  display: block;
  margin-bottom: 15px;
  padding-bottom: 0;
}
.qa_list li {
  padding: 0;
  gap: 15px;
  align-items: flex-start;
}
.qa_body {
  padding: 10px 15px 15px;
}

.qa_body li {
  margin-bottom: 20px;
}
.qa_list li .listSubheading {
  flex: 0 0 95px;
  max-width: 95px;
}
.qa_body li:last-child {
  margin-bottom: 0;
}
.qa_footer {
  background: #d3dcdf;
  padding: 10px 15px;
  display: flex;
}
.all_questions_list h5 {
  font-weight: 700;
  margin-top: 20px;
}
 .easy-edit-wrapper, .easy-edit-inline-wrapper {
  flex: 0 0 100%;
  max-width: 100%;
  margin-left: 1em;
} 

 .easy-edit-component-wrapper {
  display: block;
  width: 100%;
}

.shrink_table .MuiDataGrid-columnHeaders + div {
  height: auto !IMPORTANT;
  width: inherit !IMPORTANT;
  overflow: initial !IMPORTANT;
}

.MuiDataGrid-cell, .MuiDataGrid-columnHeader {
  font-size: 14px;
}

.center_port .MuiCircularProgress-root {
  left: 50%;
  top: 50%;
  color: #30687B !important;
}
.custom_label label{
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none;
}
.MuiButton-root.Mui-disabled{
  color: rgb(255 255 255 / 26%) !important;
}

.error {
  color: red;
}

@media only screen and (max-width:767px){
  .css-120gsus img {
    max-width: 65%;
    height: auto;
}
header .css-1efvhw7 {
    display: none  !important;
}
header button.MuiIconButton-root{
    margin-left: 8px;
        padding-right: 0;
}
.MuiPaper-elevation8 hr {
    display: none;
}
div.css-g4oihj {
    margin: 0;
}

.css-6m3pyz .MuiTypography-root {
    margin: 0;
        font-size: 18px;
}

main.css-1bzsun5 {
    margin-top: 0;
    padding-top: 0;
}
.css-70qvj9 button {
    height: auto;
    padding: 5px 20px;
    margin-right: -8px;
}

.MuiPaper-elevation24 .LeftSection, .MuiPaper-elevation24 .RightSection {
  width: 100%  !important;
  float: none !important;
  overflow-x: hidden !important;
  margin-top: 0;
  margin-bottom: 0;
}
.LeftSection .MuiContainer-root, .RightSection .MuiContainer-root, .LeftSection .MuiTextField-root, .RightSection .MuiGrid-root>.MuiGrid-item, .add_question .MuiTextField-root {
  width: 100%;
  padding: 0;
}
.LeftSection .MuiContainer-root {
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 0;
}
.RightSection{
  max-height: initial;
  }
div.MuiDialog-paper{
  margin: 15px;
  width: 90%;
}

.LeftSection .MuiButton-root , .mobile_modal_content  .MuiButton-root, .RightSection .MuiButton-root {
  padding: 15px;
  display: block;
  width: 100%;
  margin: 0;
}
.RightSection .MuiContainer-root {
  margin: 0;
}
.modal_footer .MuiGrid-root {
  flex: 0 0 100%;
  width: 100%;
}
.add_question {
  display: flex !important;
  align-items: center;
  padding: 0 !important; 
}
.add_question .MuiIconButton-root {
  top: 0;
  padding-right: 0;
}
.get_question .MuiCircularProgress-root {
  top: 15px;
}
.question_list li button {
  top: 0;
}
.manual_question li {
  width: 100%;
  font-size: 15px;
}

.manual_question li:first-child {
  width: auto;
}

.manual_question li:last-child {
  padding-left: 5px;
  padding-right: 5px;
}

.manual_question li:first-child span {
  padding-left: 0;
}
label.coding_question {
  margin-left: -10px;
}
label.coding_question span {
  font-size: 15px;
}
.easy-edit-inline-wrapper input {
  width: 100% !IMPORTANT;
  margin-bottom: 15px;
  padding: 0 10px;
  border-radius: 5px;
}
.easy-edit-inline-wrapper {
  flex: 0 0 100%;
  width: 100%;
}
.easy-edit-component-wrapper {
  display: block !important;
}
.easy-edit-button[name="cancel"] {
  margin-left: 8px;
}


.add_question .MuiInputLabel-root {
  font-size: 15px;
}


.alert_popup .MuiDialogContent-root {
  overflow: initial;
}
.alert_popup .MuiContainer-root {
  width: 100%;
  text-align: center;
}
.mobile_modal_content .MuiContainer-root {
  width: 100%;
}
.modal_footer {
  padding: 16px !IMPORTANT;
}
.mobile_modal_content .MuiContainer-root {
  padding: 0;
  margin-top: 5px;
  margin-bottom: 5px;
}
.mobile_modal_content .MuiDialogTitle-root {
  font-size: 18px;
  padding-right: 50px;
}
.mobile_modal_content .MuiDialogContent-root {
  padding-top: 16px !IMPORTANT;
}


.view-candidates-parent .MuiDialog-paper, .view-candidates-parent .MuiPaper-root {
  width: 100% !IMPORTANT;
}
.view-candidates.MuiBox-root {
  width: 100% !IMPORTANT;
  margin-top: 0;
  padding-top: 0;
}
.view-candidates .MuiDialogTitle-root {
  padding-left: 5px;
  font-size: 18px;
  padding-right: 50px;
}
.view-candidates .MuiDialogTitle-root  button {
  right: 0 !important;
  padding-right: 0 !IMPORTANT;
}
.view-candidates .MuiDataGrid-main {
  margin-top: -20px;
}


.view-candidates .MuiDataGrid-columnHeader, .view-candidates .MuiDataGrid-cell , .test_table .MuiDataGrid-columnHeader, .test_table .MuiDataGrid-cell{
  min-width: 150px !important;
  max-width: 100% !important;
  width: 100% !IMPORTANT;
}
.test_table .MuiDataGrid-columnHeader:first-child, .test_table .MuiDataGrid-cell:first-child, .view-candidates .MuiDataGrid-cell:last-child, .view-candidates .MuiDataGrid-columnHeader:last-child{
  min-width: 70px !important;
  max-width: 70px !IMPORTANT;
}
.MuiDataGrid-root .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell div {
  overflow: initial !important;
}
.get_question {
  margin-top: 20px !important;
}
.title_header {
  gap: 15px;
}
.qa_list li {
  gap: 10px;
}
.qa_list li span {
  margin-right: 5px;
}
}

.interview_questions_heading {
  width : 85% !important;
}
.interview_questions_buttons {
  width : 10% !important;
}
.question_list {
  list-style: auto !important;
  margin-bottom: 15px !important;
}
.s-no {
  margin-left: 10px!important;
}
.list_item{
  display: block !important;
}

.extra_questions li:first-child {
  flex-grow: 1;
}

.extra_questions li:last-child {
  width: auto;
}
.extra_questions {
  border-bottom: 1px solid #eaeaea;
}
.extra_questions li:last-child button {
  top: 0;
}


@media only screen and (max-width:767px){
  .btn_group {
    flex-direction: column !important;
    padding-left: 15px !important;
}
.easy-edit-wrapper, .easy-edit-inline-wrapper {
  margin-left: 0;
  flex: auto;
}
.range_slide {
  margin-bottom: 20px !important;
}
.all_questions_list {
  padding: 10px !IMPORTANT;
}
.questions {
  flex: 0 0 100%;
  width: 100%;
}
.mobile_full {
  width: calc(100% - 10px);
  margin: 0 10px;
}
.interview_questions_buttons {
  width: 100% !important;
}
.view-candidates .MuiDialogTitle-root .row {
  margin: 0;
}
.view-candidates .MuiDialogTitle-root .delete_btn {
  width: 100% !important;
  padding: 10px 30px !important;
}
}