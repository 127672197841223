* {
  font-family: "Open Sans", "sans-serif";
}

.sidebar_items a {
  color: black !important;
}

::-webkit-scrollbar {
  height: 8px;
  overflow: visible;
  width: 8px;
}

/* width */
div::-webkit-scrollbar {
  width: 8px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* Track */
div::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
div::-webkit-scrollbar-thumb {
  background: #bbbfc1;
  border-radius: 5px;
}

/* Handle on hover */
div::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.request_rejected {
  color: crimson;
  font-weight: 500;
}

.request_pending {
  color: rgb(35, 19, 179);
  font-weight: 500;
}

.request_approved {
  color: rgba(22, 105, 6, 0.877);
  font-weight: 500;
}

/* Data grid table css */
.MuiDataGrid-columnHeaders {
  background-color: #ebf0f2;
  color: #30687b;
}

.MuiDataGrid-columnHeaderTitle {
  font-weight: bold !important;
}

.MuiDataGrid-row {
  border-bottom: 1px solid #f1f1f1;
}

.MuiDataGrid-main .MuiSvgIcon-root {
  color: #000;
}

.error-message {
  color: #B7345C;
  font-size: 0.75rem !important;
  font-weight: 400 !important;
  line-height: 1.66 !important;
  font-family: "Open Sans", "sans-serif" !important;
  margin: 3px 14px 0 14px !important;
}

.success-message {
  color: green;
  font-weight: 500;
}

.dashboard-text-style {
  font-size: 8px;
}

.MuiDataGrid-cell,
.MuiDataGrid-cell p {
  text-align: left !important;
}

.MuiBadge-dot {
  background-color: #b7345c !important;
  top: 5px !important;
  right: 5px !important;
}

.MuiPaper-root.WorkOrderTable .MuiTableCell-root {
  text-transform: capitalize;
  font-size: 14px;
}

.MuiPaper-root.WorkOrderTable .css-bjwde-MuiTableCell-root {
  padding: 30px;
}

.MuiPaper-root.WorkOrderTable .css-khvgs2-MuiTableHead-root .MuiTableCell-root {
  color: #30687b;
}

.danger {
  color: red;
}

.MuiTableHead-root .MuiTableCell-root.MuiTableCell-head {
  text-transform: capitalize;
  line-height: 2;
  font-size: 16px;
  background-color: #ebf0f2;
  color: #30687b;
}

.MuiCollapse-root .MuiTableHead-root .MuiTableCell-root.MuiTableCell-head {
  color: #474747;
  background-color: #f9f9f9;
  border-bottom: 1px solid #30687b;
}

.MuiCollapse-root {
  background-color: #f9f9f9;
}

.css-1w5762t-MuiTableCell-root {
  color: #7b7b7b !important;
}

.css-rtug0o-MuiTableCell-root {
  color: #7b7b7b !important;
}

.MuiTableCell-root.MuiTableCell-body {
  border-bottom: 1.5px solid #ebf0f2 !important;
}

/* Tablecell Header */
.css-khvgs2-MuiTableHead-root .MuiTableCell-root {
  text-transform: capitalize !important;
}

.table-row--active,
.table-row--active:hover {
  border: 2px solid #0C2772;
  font-weight: 600;
  border-radius: 8px;
}

.exportButton {
  text-decoration: none !important;
}

/* profile page css */

.profile-page-title {
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  color: #30687b;
}

.details-tab .detaila-field label {
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.15px;
  color: #30687b;
}

.details-tab .detaila-field input {
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
  -webkit-text-fill-color: rgba(0, 0, 0, 0.87);
}

.details-tab .detaila-field fieldset {
  border: none;
}

.profile-details-title span {
  color: #000;
  font-weight: 700;
  font-size: 18px;
  line-height: 19px;
}

.edit-icon {
  font-size: 1.2rem !important;
}

.profile-and-pwd .profile-btn a,
.profile-and-pwd .profile-btn {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
}

/*Profile Dropdown */
.userProfileName p {
  width: 134px;
  height: 16.42px;
  left: 1222px;
  top: 117.97px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.6);
  width: auto;
}

.userProfileName span {
  /* position: absolute; */
  width: 62px;
  height: 19.5px;
  left: 1222px;
  top: 97.45px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
}

.profileDropdown p {
  font-family: "Open Sans";
  left: 1175px;
  top: 170.31px;
  padding-left: 12px;
  padding-right: 12px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
}

.css-nz18ki-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  border-radius: 4px !important;
}

/*Menu list */
li.MuiMenuItem-root.MuiMenuItem-gutters.MuiButtonBase-root.css-1wkpt53-MuiButtonBase-root-MuiMenuItem-root {
  padding-left: 25px;
  padding-right: 25px;
}


.onHover {
  margin-left: 8px;
  text-decoration: none;
}

.onHover:hover {
  text-decoration: underline;
  color: #30687b !important;
}

/* search input feild in all moduls */
.search-inputField input {
  padding: 15px 14px !important;
}

.error-message {
  color: red;
  font-weight: 400;
  font-weight: 400 !important;
  line-height: 1.66 !important;
  font-family: "Open Sans", "sans-serif" !important;
  margin: 3px 14px 0 14px !important;
}

/* scroll bar to all modules */
body::-webkit-scrollbar {
  width: 8px;
}

body::-webkit-scrollbar-track {
  background: #fff;
  /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: #919191;
  border-radius: 2px;
}

.filterBy-scroll-items span {
  font-size: 13px;
  line-height: 17.7px;
}

/* cases details and project details page */
.addHoverUnderLine{
  color: #a7a5a5;
  text-decoration: none;
}
.addHoverUnderLine:hover{
 text-decoration: underline;
}

/* .buttonTheme{
  box-shadow: 0 5px 15px rgba(0,0,0,1);
  background: linear-gradient(to top, #5A42B9, #0C2772);
} */
.buttonTheme{
  box-shadow: 0 5px 15px rgba(0,0,0,1);
  background: #306a88 !important;
}

.css-jzfvx-MuiDataGrid-root {
  font-size: 1rem !important;
}

.MuiDataGrid-row{
  /* max-height: 100% !important; */
  min-height: 52px;
  max-height: 1200px !important;
}
  
.css-jzfvx-MuiDataGrid-root .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell div {
  overflow: auto !important;
  white-space: wrap !important;
  white-space: 'normal';
  word-wrap: 'break-word';
  max-height: 1200px !important;
}
.MuiDataGrid-cell {
  max-height: 100% !important;
}

.candidates-grid.css-jzfvx-MuiDataGrid-root .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell div {
  overflow: hidden !important;
}
.easy-edit-inline-wrapper input, .easy-edit-inline-wrapper textarea {
  width: 100% !important;
  border: 1px solid #e4e4e4;
  overflow: auto;
  height: 50px;
  background: #eaeaea;
  padding: 15px;
  margin: 10px 0;
}
.easy-edit-inline-wrapper textarea{
  height: auto;
  margin: 0;
}
.all_questions_list .easy-edit-wrapper {
  display: list-item;
  margin-left : 1em; 
}

.easy-edit-inline-wrapper input:focus-visible , .easy-edit-inline-wrapper textarea:focus-visible{
  outline: none;
}

.easy-edit-button[name="cancel"]{
  background: #a5a5b0;
  color: #fff;
  box-shadow: chocolate;
  border-radius: 5px;
  width: 72px;
  font-weight: 700;
}

.easy-edit-button[name="save"]{
  background: #3c389e;
  color: #fff;
  box-shadow: chocolate;
  border-radius: 5px;
  width: 72px;
  font-weight: 700;
}
.ai-question-list {
  list-style: disc !important;
  margin-left: 20px !important;
}
/* .listSubheading {
  display: contents;
} */
/* .all_questions_list .easy-edit-wrapper {
  display: list-item;
  margin-left : 1em; 
} */
.pdf-header {
  font-weight : 700;
  text-align : center;
}

.pdf-sub-header{
  font-weight : 700;
  color : #656464
}

.left-margin {
  margin-left: 80px !important;
}
.pdf-typography {
  line-height: 30px !important;
  color : #656464
}
.chart-details-first{
  border: 2px solid firebrick;
  background: firebrick;
}

.chart-details-second{
  border: 2px solid tomato;
  background: tomato;
}

.chart-details-third{
  border: 2px solid gold;
  background: gold;
}

.chart-details-four{
  border: 2px solid limegreen;
  background: limegreen;
}
.chart-details-size {
  width: 18px;
  height: 18px;
  margin-top: 5px;
  margin-left: 3px;
}
.pdf-report .css-1h03piu-MuiTypography-root{
  font-size: 14px !important;
}
.pdf-report {
  width: 100% !important;
  font-size: 14px !important;
}

.pdf-report .Row {
  display: table;
  width: 100%; /*Optional*/
  table-layout: fixed; /*Optional*/
  border-spacing: 0 10px; /*Optional*/
  font-size: 14px !important;
}
.pdf-report .Column {
  display: table-cell;
  text-transform: capitalize;
  /* font-size: 14px !important; */
}
.pdf-report .Column:nth-child(3) {
  text-align: right;
  white-space: nowrap;
}
.pdf-report .Column:nth-child(2) {
  text-align: center;
}
.pdf-report .css-10wh27f-MuiTypography-root{
  font-size: 14px !important;
}

.report-list {
  list-style: none;
}
.report-list-div {
  font-size: 12px !important;
}
.side-list {
  margin-top: 10px !important;
}
.testNameHeading{
  /* text-align: center; */
  color : #818181;
  text-transform: capitalize;
}
.section-percentage { 
  margin-top: -103px !important;
}
path.rc-progress-line-trail {
  stroke-width: 5px;
}
.percent_bar {
  display: flex;
  align-items: center;
  gap: 10px;
}
.percent_bar span {
  white-space: nowrap;
}
.pdf-report .Row.data_row > .Column:nth-child(2) {
  padding-left: 80px;
}
.pdf-report .Row.data_row .Column:nth-child(2) {
  text-align: left;
}
.data_row .report-list-div {
  padding-left: 10px;
}
.test-log .report-list {
  line-height: 30px !important;
}